import { export_json_to_excel } from '@/assets/excel/Export2Excel'
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
// import WelCome from '../views/WelCome.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/Register',
		name: 'Register',
		component: () => import('../views/Register.vue')
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../views/404.vue')
	},
	{
		path: '/front',
		name: 'front',
		component: () => import('../views/front/front.vue'),
		children:[
			{
			path: 'home',
			name: 'fronthome',
			component: () => import('../views/front/home.vue')			
			},
			{
			path: 'frontperson',
			name: 'frontperson',
			component: () => import('../views/front/person.vue')			
			},
			{
			path: 'frontproduct',
			name: 'frontproduct',
			component: () => import('../views/front/product.vue')			
			},
			{
			path: 'frontchangepwd',
			name: 'frontchangepwd',
			component: () => import('../views/front/ChangePWD.vue')			
			},
			{
			path: 'frontaboutview',
			name: 'frontaboutview',
			component: () => import('../views/front/AboutView.vue')			
			},
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

//提供一个重置路由的方法
export const resetRouther =() =>{
	router.matcher = new VueRouter({
		mode: 'history',
		base: process.env.BASE_URL,
		routes		
	})
}

export const setRoutes = () => {
	const storeMenus = sessionStorage.getItem("sysmenu");
	if (storeMenus) {
		//拼接动态路由
		const managerRoute = {
			path: '/home',
			name: 'home',
			component: HomeView,
			meta: {
				title: '首页',
			},
			redirect: '/welcome',
			children: [{
				path: '/welcome',
				name: 'welcome',
				component: () => import('../views/WelCome.vue'),
				meta: {
					title: '欢迎',
				}
			},{
				path: '/person',
				name: 'person',
				component: () => import('../views/person'),
				meta: {
					title: '账户信息',
				}
			},{
				path: '/about',
				name: 'about',
				component: () => import('../views/AboutView'),
				meta: {
					title: '关于',
				}
			},{
				path: '/changpwd',
				name: 'changpwd',
				component: () => import('../views/ChangePWD'),
				meta: {
					title: '关于',
				}
			},{
				path: '/enterpriseinfo',
				name: 'enterpriseinfo',
				component: () => import('../views/enterpriseinfo'),
				meta: {
					title: '企业认证',
				}
			}
			 ]
		};
		const menus = JSON.parse(storeMenus)
		//console.log(menus)
		menus.forEach((item) => {

			if (item.pagepath.length>0 && item.pid==0) {
				
				var itemMenu = {
					path: item.menupath,
					name: item.menupath.replace("/", ""),
					component: () => import('../views/'+item.pagepath+'.vue'),
					meta: {
						title: item.menuname,
					}
				};
				managerRoute.children.push(itemMenu);
			}else{
				item.children.forEach((item)=>{
						var itemMenu = {
							path: item.menupath,
							name: item.menupath.replace("/", ""),
							component: () => import('../views/'+item.pagepath+'.vue'),
							meta: {
								title: item.menuname,
							}
						};
						//console.log(item.pagename);
						managerRoute.children.push(itemMenu);
				})							
			}
		});
		const currentroutename=router.getRoutes().map(v=>v.name)
		if(!currentroutename.includes("home")){
			//动态路由添加到路由对象中去
			//console.log(managerRoute);
			router.addRoute(managerRoute)			
		}
	}
}

//重置再set一次路由，些句必须
setRoutes();

//路由守卫
router.beforeEach((to,from,next)=>{
	document.title = "就业创业系统"
	if(!to.matched.length){
		const storeMenu=sessionStorage.getItem("sysmenu");
		//console.log(storeMenu)
		if(storeMenu){
			next("/404")
		}else{
			next("/")
		}		
	}else{
		next()
	}
	
})


export default router
