import Vue from 'vue'
//import Vue from 'vue/dist/vue.js'
import App from './App.vue'
import router from './router'
import store from './store'


//导入axios
import axios from 'axios';
Vue.prototype.$axios = axios; //挂载到Vue

//引入request.js，js中封装了axios方法
import request from "./utils/request";
Vue.prototype.$request = request;

//导入easy-ui
import 'vx-easyui/dist/themes/default/easyui.css';
import 'vx-easyui/dist/themes/icon.css';
import 'vx-easyui/dist/themes/vue.css';
import EasyUI from 'vx-easyui';
import locale from 'vx-easyui/dist/locale/easyui-lang-zh_CN'
Vue.use(EasyUI, {
	locale: locale
});


//导入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//导入图片预览组件
//import Preview from "@/components/PreviewItem/preview.js";
import Preview from "@/components/preview.js";
Vue.use(Preview);

//下面三行解决同一个跟帖链接被再次点击时报错的方法
import Router from 'vue-router'
// import request from './utils/request';
// Vue.prototype.request=request;
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
};




Vue.config.productionTip = false

new Vue({
	router,
	store,  //store:store 和router一样，将创建的Vuex实例挂载到这个vue实例中
	render: h => h(App)
}).$mount('#app')
